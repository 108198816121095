import * as React from "react"
import * as styles from "./heroHeader.module.scss"

import logo from "../../images/logo_black.svg"
import PopoutMenu from "../Header/PopoutMenu"

const Header = (): JSX.Element => (
  <div className={styles.container}>
    <img className={styles.logo} src={logo} alt="" />
    <div className={styles.menuWrapper}>
      <PopoutMenu dark />
    </div>
  </div>
)

export default Header
