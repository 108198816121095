import * as React from "react"
import * as styles from "./hero.module.scss"
import HeroHeader from "../../HeroHeader"

import genieInApp from "../../../images/genie-in-appb.png"
import rocket from "../../../images/icons/rocket.png"

const Header = (): JSX.Element => (
  <div className={styles.container}>
    <HeroHeader />
    <div className={styles.contentContainer}>
      <h1 className={styles.title}>Hey Cambridge</h1>
      <h6 className={styles.byline}>
        Drinks and snacks delivered to your door in 15 minutes or less{" "}
        <img className={styles.icon} src={rocket} alt="" />
      </h6>
      <img className={styles.mobileImage} src={genieInApp} alt="" />
    </div>
  </div>
)

export default Header
