import * as React from "react"
import * as styles from "./dija.module.scss"
import dijaLogo from "../../../images/dija_logo.png"
import rocket from "../../../images/icons/emojis/rocket.png"

const Dija = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.contentContainer}>
      <div className={styles.textContainer}>
        <h4 className={styles.title}>Genie is now Dija!</h4>
        <p className={styles.text}>
          Genie’s acquisition means our customers will get access to more
          products, better prices and even faster delivery times! We look
          forward to continuing to serve you under the Dija brand.
        </p>
        <a
          href="https://www.dijanow.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className={styles.button}>
            Go to Dija
            <img className={styles.rocketIcon} src={rocket} alt="" />
          </button>
        </a>
      </div>
      <div className={styles.iconContainer}>
        <img src={dijaLogo} alt="Dija Logo" />
      </div>
    </div>
  </div>
)

export default Dija
