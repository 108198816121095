import * as React from "react"
import * as styles from "./asSeenIn.module.scss"

import camindi from "./icons/CamindiLogo.svg"
import cityAM from "./icons/CityamLogo.svg"
import techcrunch from "./icons/TechcrunchLogo.svg"
import theTab from "./icons/ThetabLogo.svg"

const AsSeenIn = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.contentContainer}>
      <div className={styles.textContainer}>
        <h4 className={styles.title}>As seen in</h4>
      </div>
      <div className={styles.iconsContainer}>
        <div className={styles.iconContainer}>
          <img src={camindi} alt="" />
        </div>
        <div className={styles.iconContainer}>
          <img src={cityAM} alt="" />
        </div>
        <div className={styles.iconContainer}>
          <img src={techcrunch} alt="" />
        </div>
        <div className={styles.iconContainer}>
          <img src={theTab} alt="" />
        </div>
      </div>
    </div>
  </div>
)

export default AsSeenIn
