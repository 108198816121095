import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/index/Hero"
import AsSeenIn from "../components/index/AsSeenIn"
import Dija from "../components/index/Dija"
import IndexHeader from "../components/index/IndexHeader"
import Footer from "../components/Footer"
import Information from "../components/index/Information"

const IndexPage = (): JSX.Element => (
  <Layout>
    <SEO />
    <IndexHeader />
    <Hero />
    <Information />
    <AsSeenIn />
    <Dija />
    <Footer />
  </Layout>
)

export default IndexPage
