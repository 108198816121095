import * as React from "react"
import * as styles from "./indexHeader.module.scss"

import partyPopper from "./party_popper.png"

const IndexHeader = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.contentContainer}>
      <span className={styles.text}>
        <img src={partyPopper} alt="" /> We’re delighted to announce Genie has
        been acquired by Dija. Read the coverage in{" "}
        <a
          href="https://techcrunch.com/2021/03/17/dija-acquires-cambridge-based-genie/"
          target="_blank"
          rel="noopener noreferrer"
        >
          TechCrunch
        </a>{" "}
        and{" "}
        <a
          href="https://www.cityam.com/dija-acquires-genie-in-bid-to-expand-10-minute-delivery-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          CityAM
        </a>
        .
      </span>
    </div>
  </div>
)

export default IndexHeader
