import * as React from "react"
import * as styles from "./information.module.scss"

import mockups from "../../../images/mockups.png"
import moneyWithWings from "../../../images/icons/money-with-wings.png"
import owl from "../../../images/icons/owl.png"
import stopwatch from "../../../images/icons/stopwatch.png"

const details = [
  {
    title: "Open Late",
    description: "We're up into the early hours, just like you ;)",
    emoji: owl,
  },
  {
    title: "Great Selection",
    description:
      "Your favourite brands at affordable prices when you want them most!",
    emoji: moneyWithWings,
  },
  {
    title: "Speed",
    description:
      "Our hyper local warehouses mean you'll get your delivery in 15 minutes or less.",
    emoji: stopwatch,
  },
]

const Information = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.imageContainer}>
      <img className={styles.image} src={mockups} alt="" />
    </div>
    <div className={styles.textContainer}>
      <h4 className={styles.title}>Why you'll love genie!</h4>
      <div className={styles.itemsContainer}>
        {details.map((detail) => (
          <>
            <h6 className={styles.itemTitle}>
              {detail.title}{" "}
              <img className={styles.emoji} src={detail.emoji} alt="" />
            </h6>
            <p className={styles.itemByline}>{detail.description}</p>
          </>
        ))}
      </div>
    </div>
  </div>
)

export default Information
